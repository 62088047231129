<template>
    <div id="app-lesson" class="app-add" style="height:calc(100% - 20px)">
      <iframe id="iframe"  style="width:100%; height:100%; border: 0px" ></iframe></div>
</template>

<script>
    import { LessonTypeEnum } from "../../../public/js/common-vue";
    import {LiveStatusEnum} from "../../../public/js/common-vue";
    import {UrlEnum} from "../../../public/js/common-vue";
    import { request } from "../../../public/js/common-vue";

    window.addEventListener("message",function (event){
      var data=event.data;
      console.log(data);
    })
    export default {
        data() {
            return {
              loading: false,
              lesson: {
                roomId:'',
                token:''
              }
            }
        },
        methods: {
          initData: function () {
                this.$http({
                    method: "get",
                    url: UrlEnum.LESSON + "/" + request('id'),
                }).then((res) => {
                    this.lesson = res.data;
                    const type =  this.lesson.type;
                    const status = this.lesson.status;
                    if(type == LessonTypeEnum.VOD || type == LessonTypeEnum.LIVE_SUPPLY){
                      this.getVodToken();
                    }
                    else if(type == LessonTypeEnum.LIVE){
                      if(status == LiveStatusEnum.LIVING){
                        this.getLiveToken();
                      }
                      else{
                        this.getPlaybackToken();
                      }
                    }
                }).catch((res) => {
                    if(res.response != null){
                        const data = res.response.data;
                        this.showMsgError(data.message);
                    }
                });
            },
          getPlaybackToken: function (){
            this.$http({
              method: "get",
              url: UrlEnum.LESSONS_PLAYBACK_TOKEN+"?lessonId=" + request('id'),
            }).then((res) => {
              this.lesson.roomId = res.data.roomId;
              this.lesson.token = res.data.roomToken;
              document.getElementById("iframe").src="https://e83508617.at.baijiayun.com/web/playback/index?classid=" + this.lesson.roomId +"&session_id=-1&token="+this.lesson.token;
            }).catch((res) => {
              if(res.response != null){
                const data = res.response.data;
                this.showMsgError(data.message);
              }
            });
          },
          getVodToken: function (){
            this.$http({
              method: "get",
              url: UrlEnum.LESSONS_VOD_TOKEN+"?lessonId=" + request('id'),
            }).then((res) => {
              var videoId = res.data.videoId;
              var token = res.data.token;
              document.getElementById("iframe").src="vod.html?videoId="+videoId+"&token="+token;
            }).catch((res) => {
              if(res.response != null){
                const data = res.response.data;
                this.showMsgError(data.message);
              }
            });
          },
          getLiveToken: function (){
            this.$http({
              method: "get",
              url: UrlEnum.LESSON_LIVE_SIGN+"?lessonId=" + request('id'),
            }).then((res) => {
              const roomId = res.data.roomId;
              const sign = res.data.sign;
              const userNumber = res.data.userNumber;
              const userAvatar = res.data.userAvatar;
              const userRole = res.data.userRole;
              const userName = res.data.userName;
              const src = "https://e83508617.at.baijiayun.com/web/room/enter?room_id="+roomId+"&user_number="+userNumber+"&user_name="+userName+"&user_role="+userRole+"&user_avatar="+userAvatar+"&sign="+sign;
              console.log(src);
              document.getElementById("iframe").src=src;
            }).catch((res) => {
              if(res.response != null){
                const data = res.response.data;
                this.showMsgError(data.message);
              }
            });
          }
        },
        mounted() {
            this.initData();
        }
    }
</script>

<style scoped>
    @import "../../../static/css/add.css";
</style>
